import { useState } from "react";

const SearchUtils = () => {

    const [searchTerm, setSearchTerm ] = useState("");
    const [searchType, setSearchType] = useState("movie");
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false)
    const [loaded, setLoaded] = useState(false);

    const handleClear = () => {
        setSearchTerm("");
        setSearchResults([])
    };

    return {
        handleClear, 
        searchTerm, 
        setSearchTerm, 
        searchType, 
        setSearchType, 
        searchResults, 
        setSearchResults,
        isSearching,
        setIsSearching, 
        loaded,
        setLoaded
    }

}

export default SearchUtils;