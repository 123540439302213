import { useToast } from "@chakra-ui/react";

const toast = useToast;

export const checkOnline = () => {
    window.ononline = (event) => {
      console.log("You're back online!")
      toast({
        title: "Internet Connected!",
        description: "You're back online!",
        status: "success",
        position: "bottom-right",
        duration: 6000,
        isClosable: true,
      })
    };

    window.onoffline = (event) => {
      console.log("Check your internet connection.")
      toast({
        title: "Internet Disconnected",
        description: "Check your internet connection.",
        status: "info",
        position: "bottom-right",
        duration: 6000,
        isClosable: true,
      })
    }
  };