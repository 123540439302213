import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    // fonts: {
    //   heading: "Syne",
    //   body: "Inter"
    // },
    // colors:{
    //   green: "#729d5a",
    //   blue: "#2144b5",
    //   red: "#d22b2b",
    //   yellow: "#fabb05",
    //   black: "#282c40",
    //   white: "#f2f2f2"
    // },
    config: {
      initialColorMode: "dark",
      useSystemColorMode: true,
    },
  
  });
  
  export default theme;