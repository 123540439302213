import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Center, Divider, Heading, Text, HStack, Flex, Spacer, Button, CircularProgress, CircularProgressLabel, Spinner, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, useClipboard, Input, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { getVotes, reset } from "../store/features/vote/voteSlice";
import ListItem from "./ListItem";

const UserList = ({colorMode}) => {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();

    
    const { user } = useSelector((state) => state.auth);
    const {votes, isLoading, isError, message } = useSelector((state) => state.votes)
    


    useEffect(() => {
        
        if(user == null ) {
            navigate("/")
            return;
        };

        dispatch(getVotes());

        if(isError == true) {
            toast({
                title: "Something went wrong!",
                description: `${message}\n If you believe this is an error, refresh the page and try again or contact us!`,
                status: "error",
                duration: 4000,
                position: "top",
                isClosable: false,
            });
        };

        return () => {
            dispatch(reset());
        }
    }, [user, navigate, dispatch, isError, message, toast]);

    // const [value, setValue] = useState(`devfilmx.lekhhaq.com/${username}`);

    const username = !user ? "user" : user.username;
    const {hasCopied, onCopy } = useClipboard(`${process.env.REACT_APP_SHARE_URL}${username}`);

    const maxPoints = votes.votesAvailable;

    return (

        isLoading 
        ?
        <Center>
        <Spinner 
            m={20}
            size="xl" />
        </Center> 
        :
        <>

        <Box
            px={[5, 5, 5, 40]}
            pt={16}
            pb={2}>
            <Divider variant="dashed" mb={4}/>
            <Box pb={8} align={"center"} justify="center">
            <Center>
            <Flex pb={4} >
            <HStack>
                <Heading 
                bgGradient={colorMode === "light" 
                ?
                'linear(to-r, blue.600, blue.400)' 
                : 
                'linear(to-r, blue.200, blue.300)'}
                bgClip='text'
                fontSize="4xl"
                fontWeight='bold'
                px={[4, 4, 10, 10]}>Your Movie List!</Heading>
                <Spacer />
                <CircularProgress
                capIsRound
                thickness={8}
                trackColor={colorMode === "light" ? "gray.200" : "whiteAlpha.200"}
                color={ maxPoints >= 75 
                ? 
                "red.500" 
                :
                maxPoints >= 50
                ?
                "blue.600"
                :
                maxPoints >= 11
                ?
                "blue.300"
                :
                maxPoints <= 10
                ?
                "green.500"
                :
                "black"}
                size={28}
                value={maxPoints}
                max={0}
                min={100}>
                {
                    maxPoints < 1 
                    ?
                    <CircularProgressLabel 
                    fontSize={28}>
                    🎉
                    </CircularProgressLabel>
                    :
                    <CircularProgressLabel 
                    fontSize={28}>
                    {maxPoints}
                    </CircularProgressLabel>
                }
                </CircularProgress>
                </HStack>
            </Flex>
            </Center>
            {/* <Divider variant="dashed" /> */}
            <Accordion allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign="center">
                                Share Your List?
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel py={4} >
                        <em>Copy this link to share your list!</em>
                        <br />
                        <Flex m={4} maxW="lg">
                        <Input 
                            value={`${process.env.REACT_APP_SHARE_URL}${username}`} 
                            variant={"filled"}
                            isReadOnly 
                            placeholder="filmx" 
                            size={"md"}
                            />
                        <Button onClick={onCopy} ml={4}>
                            {hasCopied ? "🚀" : "🔗"}
                        </Button>
                        </Flex>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            </Box>


            {!votes.results 
            ?
            <Text>No films here!</Text>
            :
            votes.results.map((item) => {

                const directorSearch = item.movie.credits.crew;
                const directorSearch2 = directorSearch.find(obj => obj.job === "Director");
                const director = directorSearch2 === undefined ? "Not Available" : directorSearch2.name
                
                return (
                    <ListItem
                        key={item.movie.id}
                        id={item.movie.id}
                        colorMode={colorMode}
                        title={item.movie.title}
                        country={item.movie.production_countries[0] ? item.movie.production_countries[0].iso_3166_1 : ""}
                        language={item.movie.original_language}
                        release_date={item.movie.release_date}
                        poster_path={item.movie.poster_path}
                        maxPoints={user.votesAvailable}
                        _votes={item.vote.votes}
                        director={director}
                        // writer={writer}
                    />
                );
            })}

            
        </Box>
        </>
    );
};



export default UserList;