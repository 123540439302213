import { Box, Center } from "@chakra-ui/react";


export default function Feedback() {
  return (
    <Box mt={24}>
    <Center>
      <iframe 
      src="https://docs.google.com/forms/d/e/1FAIpQLSe1hIOukjOZQxEwShsovmTHIdDi8Lmpwk95DTSN1VILBtNwig/viewform?embedded=true" 
      width="640" height="1250" 
      frameBorder="0" 
      marginHeight="0" 
      marginWidth="0">
      Loading…
      </iframe>
    </Center>
    </Box>
  )

}