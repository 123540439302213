import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from "react-router-dom";
import { ChakraProvider, ColorModeScript, Flex } from "@chakra-ui/react";
import App from './App';
import { store } from './store/store';
import {Provider} from "react-redux";
import theme from './theme';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Router>
    <ChakraProvider theme = {theme} resetCSS>
    <ColorModeScript initialColorMode="dark" />
    <Flex direction="column" w="100%" h="93vh">
    <Provider store={store}>
      <App />
    </Provider>
    </Flex>
    </ChakraProvider>
    </Router> 
);