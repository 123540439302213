import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { logout } from "../auth/authSlice";
import voteService from "./voteService";

const initialState = {
    votes: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
}

//create new vote
export const createVote = createAsyncThunk("votes/createVote", async (voteData, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        await voteService.createVote(voteData, token);
        thunkAPI.dispatch(getVotes(token));
    } catch (error) {
        if(error.response.status === 401) {
            return thunkAPI.dispatch(logout());
        } else {
        const message = 
        (error.response && 
            error.response.data && 
            error.response.data.message) 
            || error.message 
            || error.toString()
        return thunkAPI.rejectWithValue(message);
        }
    }
})

//get all voted films
export const getVotes = createAsyncThunk("votes/getVotes", async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        return await voteService.getVotes(token);
    } catch (error) {
        if(error.response.status === 401) {
            return thunkAPI.dispatch(logout());
        } else {
        const message = 
        (error.response && 
            error.response.data && 
            error.response.data.message) 
            || error.message 
            || error.toString()
        return thunkAPI.rejectWithValue(message);
        }
    }
})

export const voteSlice = createSlice({
    name: "vote",
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(createVote.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createVote.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.message = action.payload
            })
            .addCase(createVote.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getVotes.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getVotes.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.votes = action.payload
            })
            .addCase(getVotes.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
});

export const {reset} = voteSlice.actions;
export default voteSlice.reducer;