import axios from "axios";
import { API_BASE_URL } from "../../../config";

//Create vote
const CREATEVOTE_URL = `${API_BASE_URL}/movies/user/vote`
// const CREATEVOTE_URL = `http://localhost:5000/movies/user/vote`

const createVote = async (voteData, token) => {
    const config = {
        headers: {
            authorization: `Bearer ${token}`
        }
    } 

    const response = await axios.post(CREATEVOTE_URL, voteData, config)
    // console.log(response);
    return response.data
}

//Get all votes
const GETVOTE_URL = `${API_BASE_URL}/user/movies`

const getVotes = async (token) => {
    const config = {
        headers: {
            authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(GETVOTE_URL, config)

    return response.data;
}

const voteService = {
    createVote,
    getVotes
}

export default voteService;