import { Routes, Route, useLocation } from "react-router-dom";
import { useColorMode, Box, } from "@chakra-ui/react";
import DiscoveryPalette from "./components/DiscoveryPalette";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import UserList from "./components/UserList";
import SignUp from "./components/SignUp";
import LandingPage from "./components/LandingPage";
import FAQ from "./components/FAQ";
import Feedback from "./components/Feedback";
// import { checkOnline } from "./utils/CheckOnline";
import ShareList from "./components/ShareList";
import OtpVerification from "./components/OtpVerification";
import NotFound from "./components/NotFound";
import ToS from "./components/ToS";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {

  const location = useLocation();
  const { colorMode, toggleColorMode } = useColorMode();

  // checkOnline();
  
  return (
    <>
    <Box zIndex={2}>
      <NavBar 
        colorMode={colorMode}
        toggleColorMode = {toggleColorMode}
        />
    </Box>
    <Routes location={location} key={location.pathname}>

            <Route
              path="/"
              element = {
                <LandingPage 
                colorMode={colorMode}
                />
            } />
            
            <Route
              path="/mylist"
              element = {
                <>
                <UserList
                colorMode={colorMode}/>
                
                <DiscoveryPalette
                colorMode = {colorMode} />

              </>
              } />

              <Route
              path="/:username"
              element = {
                <ShareList 
                  colorMode = {colorMode}
                />
              } />
            
            <Route
              path="/sign-up"
              element = { 
                <SignUp 
                  colorMode={colorMode}
                />
              } />

            <Route
              path="/verification"
              element = { 
                <OtpVerification 
                  colorMode={colorMode}
                />
              } />

            <Route
              path="FAQ"
              element = {
                <FAQ 
                colorMode={colorMode}
                />
              } />

            <Route
              path="feedback"
              element = {
                <Feedback />
              } />

              <Route
                path="/terms-of-service"
                element = {
                  <ToS />
                } />
              
              <Route
                path="/privacy-policy"
                element = {
                  <PrivacyPolicy />
                } />

                <Route
                  element= {
                    <NotFound />
                  } />

        </Routes>

        <Box>
          <Footer 
            colorMode={colorMode}
          />
        </Box>
    </>
  );
}

export default App;