import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Box, Button, ButtonGroup, Center, Divider, Flex, Heading, Link, Spinner, Text, VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom'
import NotFound from './NotFound';
import ShareListItem from './ShareListItem';
import { API_BASE_URL } from '../config';

export const ShareList = (colorMode) => {

    const {username} = useParams(); 

    const [ isLoading, setIsLoading ] = useState(false);
    const [ userList, setUserList ] = useState([]); 
    const [ error, setError ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("")

    useEffect(() => {
        const showList = async() => {
            setIsLoading(true)
            await axios.get(`${API_BASE_URL}/user/${username}`)
            .then((result) => {
                // console.log(result);
                setUserList(result.data.results);
                setIsLoading(false);
            }).catch((err) => {
                console.log(err);
                setErrorMessage(err.response.data.message);
                setIsLoading(false);
                setError(true);
            })
        }
        showList();
    }, [username])

    // console.log(errorMessage)
    // console.log(userList);

    return (

        isLoading 
        ?
        <Center my={36}>
        <Spinner 
            size="xl" />
        </Center> 
        :

        error ?

        <NotFound 
            errorMessage = {errorMessage}
        />

        :

        <>

        <Box
            px={[5, 5, 5, 40]}
            pt={16}
            pb={2}>
            <Divider variant="dashed" mb={4}/>
            <Box pb={8} align={"center"} justify="center">
            <Center>
            <Flex pb={4} >
                <Heading
                pointerEvents="none" 
                bgGradient={colorMode.colorMode === "light" 
                ?
                'linear(to-r, blue.600, blue.500)' 
                : 
                'linear(to-r, blue.200, blue.500)'}
                bgClip='text'
                fontSize="4xl"
                fontWeight='bold'
                px={[4, 4, 10, 10]}>{username}'s FilmX List!</Heading>
            </Flex>
            </Center>
            <Divider variant="dashed" />
            </Box>


            {userList.length === 0 
            ?
            <>
            <Box justify="center" align="center">
            <Text fontSize="lg"><i>{username} hasn't added any films yet!</i></Text>
            </Box>
            <Divider my={8}/>
            </>
            :
            userList.map((item) => {

                const directorSearch = item.movie.credits.crew;
                const directorSearch2 = directorSearch.find(obj => obj.job === "Director");
                const director = directorSearch2 === undefined ? "Not Available" : directorSearch2.name
                
                return (
                    <ShareListItem
                        key={item.movie.id}
                        id={item.movie.id}
                        colorMode={colorMode}
                        title={item.movie.title}
                        country={item.movie.production_countries[0] ? item.movie.production_countries[0].iso_3166_1 : ""}
                        language={item.movie.original_language}
                        release_date={item.movie.release_date}
                        poster_path={item.movie.poster_path}
                        // maxPoints={user.votesAvailable}
                        _votes={item.vote.votes}
                        director={director}
                        watchProvider={item.movie["watch/providers"]}
                    />
                );
            })}


            <Box 
            justify="center" 
            align="center" 
            rounded="md" 
            p="8" 
            mt={20} 
            bgColor="whiteAlpha.50">
                <Heading pb="8" size="md">Would you like to make a list, too?</Heading>
                <Box display={["none", "none", "inline", "inline"]}>
                <ButtonGroup gap="10">
                <Link href="/sign-up" style={{textDecoration: "none"}} >
                <Button variant="solid" colorScheme="green">Yes, Sign me up!</Button>
                </Link>
                <Link href="/" style={{textDecoration: "none"}} >
                <Button variant="outline" borderColor="cyan.500">Learn More</Button>
                </Link>
                </ButtonGroup>
                </Box>

                <Box display={["inline", "inline", "none", "none"]}>
                <ButtonGroup gap="10">
                <VStack>
                <Link href="/sign-up" style={{textDecoration: "none"}} >
                <Button variant="solid" colorScheme="green">Yes, Sign me up!</Button>
                </Link>
                <Link href="/" style={{textDecoration: "none"}} >
                <Button variant="outline" borderColor="cyan.500">Learn More</Button>
                </Link>
                </VStack>
                </ButtonGroup>
                </Box>

            </Box>

        </Box>
        </>
    );
}

export default ShareList;