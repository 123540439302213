import { FormControl, FormLabel, Input, InputGroup, InputLeftElement, Center, Button, FormHelperText, Container, Heading, Text, HStack, useDisclosure, VStack, Box, Link, Spinner } from "@chakra-ui/react";
import { AtSignIcon, EmailIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { register, reset } from "../store/features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import LogIn from "./Login";

export default function SignUp({colorMode}) {
    const initialValues = {username: "", fName: "", lName: "", email: ""}
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [serverError, setServerError] = useState("")
    const [isSubmit, setIsSubmit] = useState(false);

    const {isOpen: isOpenLogin, onOpen: onOpenLogin, onClose: onCloseLogin} = useDisclosure();

    let navigate = useNavigate();
    let dispatch = useDispatch();

    const {user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth )
    
    useEffect(() => {

        if(isError) {
            // console.log("formValues Error")
            setServerError(message)
            return;
        }

        if(isSuccess) {
            // console.log("Success");
            navigate("/verification")
        }

        dispatch(reset())

    }, [user, isError, isSuccess, message, dispatch, navigate])

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
        };
    
// console.log(formErrors)


    const handleSubmit = async (e) => {
        e.preventDefault();
        await setFormErrors(validate(formValues));
        // console.log(formValues)
        const userData = {
            username: formValues.username,
            firstName: formValues.fName,
            lastName: formValues.lName,
            email: formValues.email,
            // password: formValues.password,
        }
        // console.log(userData)
        await dispatch(register(userData))
        // console.log("dispatched register")
    };

    useEffect(() => {
        // console.log(formErrors);
        if(Object.keys(formErrors) === 0 && isSubmit) {
            console.log(formValues);
        }
    }, [formErrors, formValues, isSubmit])

    const validate = (values) => {
        const errors = {}
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if(!values.username) {
            errors.username = "Username is Required!"
        }
        if(!values.fName) {
            errors.fName = "First Name is Required!"
        }
        if(!values.lName) {
            errors.lName = "Last Name is Required!"
        }
        if(!values.email) {
            errors.email = "Email is Required!";
        }
        else if(!regex.test(values.email)) {
            errors.email = "This is not a valid email"
        }
        // if(!values.password) {
        //     errors.password = "Password is Required!"
        // }
        return errors;
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <>

        <Container mt={24} p={8} rounded="lg" border="1px">

        <Center>
        <VStack>
        <Heading fontSize={32} pb={4}>
            Sign up!
        </Heading>
        <Text pb={4}>
            Already a member? <Button size="lg" variant="link" color="cyan.400" onClick={onOpenLogin}>Login.</Button>
        </Text>
        </VStack>
        </Center>

        <FormControl
        isRequired>
            <FormLabel htmlFor="fName">
                Username
            </FormLabel>
            <InputGroup>
            <InputLeftElement
            pointerEvents='none'
            children={<AtSignIcon color={colorMode === "light" ? 'gray.400' : "gray.500"} />} />
            <Input 
            borderColor={formErrors.fName ? "red" : "gray.400"}
            type="text" 
            name="username"
            // placeholder="username"
            value={formValues.username}
            onChange={handleChange} />
            </InputGroup>
            <FormHelperText 
            fontWeight="semibold" 
            color="red.500">
            {formErrors.username}
            </FormHelperText>
        </FormControl>

        <FormControl
        isRequired>
            <FormLabel htmlFor="fName">
                First Name
            </FormLabel>
            <Input 
            borderColor={formErrors.fName ? "red" : "gray.400"}
            type="text" 
            name="fName"
            // placeholder="First Name"
            value={formValues.fName}
            onChange={handleChange} />
            <FormHelperText 
            fontWeight="semibold" 
            color="red.500">
            {formErrors.fName}
            </FormHelperText>
        </FormControl>

        <FormControl 
        isRequired>
            <FormLabel htmlFor="lName">
                Last Name
            </FormLabel>
            <Input
            borderColor={formErrors.fName ? "red" : "gray.400"}
            type="text" 
            name="lName" 
            // placeholder="Last Name"
            value={formValues.lName}
            onChange={handleChange} />
            <FormHelperText 
            fontWeight="semibold" 
            color="red.500">
            {formErrors.lName}
            </FormHelperText>
        </FormControl>
        
        <FormControl 
        isRequired>
            <FormLabel htmlFor="email">
                Email
            </FormLabel>
            <InputGroup>
            <InputLeftElement
            pointerEvents='none'
            children={<EmailIcon color={colorMode === "light" ? 'gray.400' : "gray.500"} />} />
            <Input 
            borderColor={formErrors.fName ? "red" : "gray.400"}
            type="email" 
            name="email" 
            // placeholder="Email"
            value={formValues.email}
            onChange={handleChange} />
            </InputGroup>
            <FormHelperText 
            fontWeight="semibold" 
            color="red.500">
            {formErrors.email}
            </FormHelperText>
        </FormControl>

        {/* <FormControl 
        isRequired
        pb={3}>
            <FormLabel htmlFor="email">
                Password
            </FormLabel>
            <InputGroup>
            <InputLeftElement
            pointerEvents='none'
            children={<LockIcon color={colorMode === "light" ? 'gray.400' : "gray.500"}/>} />
            <Input
            borderColor={formErrors.fName ? "red" : "gray.400"}
            type="password"
            name="password" 
            // placeholder="Password"
            value={formValues.password} 
            onChange={handleChange} />
            </InputGroup>
            <FormHelperText fontWeight="semibold" color="red.500">{formErrors.password}</FormHelperText>
        </FormControl> */}


            {formErrors
            ?
            <Text pb={2} fontWeight="semibold" color="red.500">{serverError}</Text>
            :
            null
            }

        <HStack spacing={14}>
        <Box>
        <Text align="left" justify="left">
            By signing up you are agreeing to our "<Link color="cyan.100" href="/terms-of-service" isExternal="true"><i>Terms of Service</i></Link>" and "<Link color="cyan.100" href="/privacy-policy" isExternal="true"><i>Privacy Policy</i></Link>". <br />
            We <b>do not</b> share your information with anyone.
        </Text>
        </Box>
        
        {
        isLoading
        ?
        <Spinner />
        :
        <Button type="submit" onClick={handleSubmit}>
        Sign Up
        </Button>
        }

        </HStack>
        </Container>




        <LogIn 
        isOpen={isOpenLogin}
        onClose={onCloseLogin} />
        
        </>
    );
};