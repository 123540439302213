import React, { useEffect, useState } from "react";
import { Box, Image, Text, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Center, HStack, useToast, Link, Tooltip, Divider } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {createVote} from "../store/features/vote/voteSlice";
// import { InfoIcon } from "@chakra-ui/icons";


const SearchResultCard = ({
    title,
    poster,
    id,
    setSearchTerm,
    userList,
    colorMode}) => {


    const { isOpen, onOpen, onClose } = useDisclosure();

    const { votes, isError, isSuccess, message } = useSelector((state) => state.votes)

    const fallback_path = "https://via.placeholder.com/342x513/000000/FFFFFF/?text=No%20Poster"

    const dispatch = useDispatch();
    const toast = useToast();

    
    const [ points, setPoints ] = useState(0);
    const [ quadraticPoints, setQuadraticPoints ] = useState(0);
    const [ maxPointsAvailable, setMaxPointsAvailable ] = useState(votes.votesAvailable);
    const [ votingError, setVotingError ] = useState(false);

    const incrementPoints = () => {
        if(points >= 10) {
            return;
        } else {
            setPoints(prevPoints => prevPoints + 1)
        }
    };
    
    const decrementPoints = () => {
        if(points <= 0) {
            return;
        } else {
            setPoints(prevPoints => prevPoints - 1)
        }
    };

    useEffect(() => {
        const quadraticCalc = () => {
            setQuadraticPoints(points * points)
        }
            quadraticCalc();
    }, [points]);


    const vote = () => {
        const voteData = {
            movieId: id,
            votes: quadraticPoints,    
        };
        // console.log(voteData);
        dispatch(createVote(voteData));
        setQuadraticPoints(0);
        setSearchTerm("");
        onClose();
        if(random <= 1){
            toast({
                title: "Great choice!🎊",
                description: `Adding ${title} to your list!`,
                status: "success",
                duration: 3000,
                position: "top",
                isClosable: false,
                onCloseComplete: window.scrollTo(0, 0)
            })
        }else{
            toast({
                title: "Play it again, Sam!🎹",
                description: `Of all the films in all the world, you had to pick ${title}!`,
                status: "success",
                duration: 7000,
                position: "top",
                isClosable: false,
                onCloseComplete: window.scrollTo(0, 0)
            })
        }
    }

    const random = Math.floor(Math.random() * 3)
    // console.log(random)

    const exists = userList.find(movieId => movieId === id)

    return (
        exists ? 
        <Box
        justify="center" 
        align="center"
        overflow="hidden"
        border="1px"
        borderColor="cyan"
        rounded="lg"
        my={2}>
            <Text 
                p={2} 
                fontSize="2xl" 
                fontWeight="semibold">
                Added to List! 😊
                 </Text>            
            <Image
                opacity="0.2"
                src={!poster ? fallback_path : `https://image.tmdb.org/t/p/w342${poster}`} 
                alt={title} 
                onClick={onOpen} />
        </Box>

        :

        <>
        
        <Box
        overflow="hidden"
        border="1px"
        borderColor="cyan"
        rounded="lg"
        my={2}>

            <Image 
                src={!poster ? fallback_path : `https://image.tmdb.org/t/p/w342${poster}`} 
                alt={title} 
                onClick={onOpen} />

        </Box>
        
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent 
                color= {colorMode === "light" ? "white" : "black.400"}
                bgGradient="linear(to-br, #1A365D, #2B6CB0)"
                border="2px" 
                borderColor="blue.700">
                <ModalHeader>
                    Add <b>{title}</b> to your list?
                </ModalHeader>
                {/* <ModalCloseButton isDisabled/> */}
                <ModalBody pb={6}>
                    <Text pb={2}>
                    How intensely do you like {title}?
                    <br />Remember, the costs rises "quadratically" to signify your unique <b>intensity</b>! <Link href="/FAQ" isExternal ><Tooltip hasArrow label="Understand intensity voting in our FAQs!"><i>What does that mean?</i></Tooltip></Link>
                    </Text>
                    <Divider shadow="md" pt={4}/>
                    <Center>
                    <Text fontSize={20} pb={8} pt={4}>You're spending {quadraticPoints}/{maxPointsAvailable} points! {quadraticPoints <= maxPointsAvailable ? "🟢" : "⛔"}</Text>
                    </Center>
                    <Center>
                    <HStack>
                    
                    <Button
                        // isDisabled={votingError} 
                        bgColor={colorMode === "light" ? "blue.500" : null} 
                        _hover={colorMode === "light" ? {bgColor: "blue.600"} : null}
                        mr={1} 
                        onClick={decrementPoints}>
                        {points >= 5 ? "🧊 -" : "-"}
                    </Button>
                        
                        <Button
                        variant="ghost"
                        pointerEvents="none"
                        bgColor={colorMode === "light" ? "white.500" : null} 
                        // _hover={colorMode === "light" ? {bgColor: "blue.600"} : null}
                        mr={1}>
                        {points}
                        </Button>

                    <Button 
                        // isDisabled={votingError}
                        bgColor={colorMode === "light" ? "blue.500" : null} 
                        _hover={colorMode === "light" ? {bgColor: "blue.600"} : null}
                        ml={1}
                        onClick={incrementPoints}>
                        {points === 0 ? "+" : points <= 3 ? "+ ⚡" : points <= 8 ? "+ 🔥" : points <=10 ? "+ 💥" : "+" }
                    </Button>
                    </HStack>
                    </Center>

                </ModalBody>
                <ModalFooter>
                    <Text 
                    display={quadraticPoints <= maxPointsAvailable ? "none" : "inline"}
                    pr={4}
                    fontWeight="semibold">
                    You don't have enough points for that. 😔</Text>
                    <Button isDisabled={points === 0 ? true : false || quadraticPoints <= maxPointsAvailable ? false : true} type="submit" bgColor={"green.500"} mr={3} onClick={vote}>
                        Add
                    </Button>
                    <Button bgColor={"red.500"} color={colorMode === "light" ? "black" : "black"} onClick={() => {setSearchTerm(""); onClose()}}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>


        </>
    )
};

export default SearchResultCard;