import React from 'react';
import {Box, Avatar, Flex, LinkBox, LinkOverlay} from '@chakra-ui/react';

const WatchProvider = (watchProvider) => {

const flatrate = watchProvider.watchProvider.flatrate || null;
const buy = watchProvider.watchProvider.buy || null;
const rent = watchProvider.watchProvider.rent || null;

// console.log(flatrate, buy, rent)

const frList = flatrate !== null ? flatrate.map(element => {
    return [element.provider_name, element.logo_path, element.provider_id]; 
}) : [];

const buyList = buy !== null ? buy.map(element => {
    return [element.provider_name, element.logo_path, element.provider_id]; 
}) : [];

const rentList = rent !== null ? rent.map(element => {
    return [element.provider_name, element.logo_path, element.provider_id];
}) : [];

  return (
    <Flex>
    {!flatrate && !buy && !rent 
    ?
    <Box py={4}>
    <strong><em>Not Currently Available</em></strong>
    </Box>
    :
    <LinkBox>
    <LinkOverlay href={`${watchProvider.watchProvider.link}`} isExternal>
    {frList.length === 0 ? 
    null
    :
    frList.map((e) => {
        return <Avatar key={e[2]} mt={4} mx={3} name={e[0]} src={`https://image.tmdb.org/t/p/w154/${e[1]}`} />
    })
    }
    {buyList.length === 0 ? 
    null
    :
    buyList.map((e) => {
        return <Avatar key={e[2]} mt={4} mx={3} name={e[0]} src={`https://image.tmdb.org/t/p/w154/${e[1]}`} />
    })
    }
    {rentList.length === 0 ? 
    null
    :
    rentList.map((e) => {
        return <Avatar key={e[2]} mt={4} mx={3} name={e[0]} src={`https://image.tmdb.org/t/p/w154/${e[1]}`} />
    })
    }
    </LinkOverlay>
    </LinkBox>
    }
    </Flex>
  )
}

export default WatchProvider