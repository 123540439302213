import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, Divider, Flex, Grid, GridItem, HStack, Image, Link, LinkBox, LinkOverlay, Progress, Spacer, Stat, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { useEffect, useState } from "react";
import WatchProvider from "./WatchProvider";
// import isoLangs from "../utils/isolangs.json";

export default function ShareListItem ({
    id,
    colorMode,
    title,
    country,
    language,
    release_date,
    poster_path,
    _votes,
    director,
    watchProvider,
    // writer,
}) {

    
    const getLanguageName = new Intl.DisplayNames(["en"], {type: "language"});
    const languageName = getLanguageName.of(language)

    const getCountryName = new Intl.DisplayNames(["en"], {type: "region"});
    const countryName = getCountryName.of(country)

    const [watchProviders, setWatchProviders] = useState({})
    
    // console.log(id);
    // console.log(watchProviders)
    
    const justWatchLogo = "https://www.themoviedb.org/assets/2/v4/logos/justwatch-small-yellow-d211a54518826b26bc4b207f2f5ecb9446bb446cfdd83ac4dc586e478eafdb6c.svg";
    
    // let _language
    // if(isoLangs.isoLangs[0][language].name === "undefined") {
        //     _language = ""
        // } else {
            //     _language = isoLangs.isoLangs[0][language].name;
            // };
    
    useEffect(() => {
        if (watchProvider.results.IN === undefined) {
            setWatchProviders("Not Currently Available")
        } else {
            setWatchProviders(watchProvider.results.IN)
        }
    }, [watchProvider.results.IN]);
            
    return (
        <>
            <Grid
            // pointerEvents="none"
            templateColumns='repeat(3, 1fr)'>
            
            <GridItem colSpan={1} justify="right" align="right" mr={[4, 4, 8, 8]}>
                <Image 
                    rounded="md"
                    border="2px" 
                    borderColor="teal.200" 
                    shadow="md" 
                    src= {`https://image.tmdb.org/t/p/w154/${poster_path}`} />
            </GridItem>

            <GridItem colSpan={2}>
                    <Box>
                    <Text fontSize="2xl" fontWeight="semibold" py={[0, 0, 1, 1]}>
                    {title},
                    </Text>
                    <Text fontSize="sm" fontWeight="semibold" py={[0, 0, 1, 1]}>
                    {release_date.slice(0, 4)},
                    </Text>
                    <Text fontSize="md" fontWeight="semibold" py={[0, 0, 1, 1]}>
                    <i>{languageName}</i>,
                    </Text>
                    <Text fontSize="lg" fontWeight="semibold" py={[0, 0, 1, 1]}>
                    Directed by {director}
                    </Text>
                    </Box>
                <Spacer />
                    <Box py={4}>
                    <Progress 
                    max="100" 
                    value={_votes} 
                    colorScheme="green" 
                    rounded="xl" 
                    height="5" 
                    maxW={96} />
                    </Box>
                    {/* <Box borderBottom={"1px"} rounded={"md"} p={2}> */}
                        {/* <Text fontSize="xl" fontWeight="semibold" pointerEvents="none" >
                            {_votes <= 9 ? "⚡" : _votes <= 64 ? "🔥" : _votes <= 100 ? "💥" : null}
                        </Text> */}

                    {/* </Box> */}

                    <Accordion allowToggle>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box flex={"1"} textAlign="left">
                                            Availability
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} maxW="full">
                                     
                                        <WatchProvider
                                        watchProvider={watchProviders} />

                                    <br/>
                                    <Divider variant={"dashed"} py={2} />
                                    <Link href="https://www.justwatch.com/" isExternal>
                                    <Flex mt={4}>
                                    <Image mr={4} boxSize={6} src={justWatchLogo} />
                                    <Text fontSize={"sm"}>Streaming data provided by JustWatch</Text>
                                    </Flex>
                                    </Link>
                                    <Link href="/FAQ" isExternal><Text pt={4} fontSize={"sm"}>Learn more about Availability <ExternalLinkIcon /></Text></Link>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>

            </GridItem>
            </Grid>

            

            <Divider my={8}/>

            </>
    );
}