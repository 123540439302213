// import { UpDownIcon } from "@chakra-ui/icons";
import { Box, Flex, Image, Spacer, Center, Text, Button, Divider, Progress, useDisclosure, useToast, Link, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Tooltip, HStack, ModalFooter, Grid, GridItem } from "@chakra-ui/react";
// import { useEffect, useState } from "react";
// import isoLangs from "../utils/isolangs.json";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import {createVote} from "../store/features/vote/voteSlice";

export default function LandingPageListItem ({
    id,
    colorMode,
    title,
    country,
    language,
    release_date,
    poster_path,
    _uniqueVotes,
    _votes,
    director,
    maxVotes
}) {
  

    const getLanguageName = new Intl.DisplayNames(["en"], {type: "language"});
    const languageName = getLanguageName.of(language)

    const getCountryName = new Intl.DisplayNames(["en"], {type: "region"});
    const countryName = getCountryName.of(country)


    return (
        <>
            <Grid
            templateColumns='repeat(3, 1fr)'>
            
            <GridItem colSpan={1} justify="right" align="right" mr={[4, 4, 8, 8]}>
                <Image 
                    rounded="md"
                    border="2px" 
                    borderColor="teal.200" 
                    shadow="md" 
                    src= {`https://image.tmdb.org/t/p/w154/${poster_path}`} />
            </GridItem>

            <GridItem colSpan={2}>
                    <Box>
                    <Text fontSize="2xl" fontWeight="semibold" py={[0, 0, 1, 1]}>
                    {title},
                    </Text>
                    <Text fontSize="sm" fontWeight="semibold" py={[0, 0, 1, 1]}>
                    {release_date.slice(0, 4)},
                    </Text>
                    <Text fontSize="md" fontWeight="semibold" py={[0, 0, 1, 1]}>
                    <i>{languageName}</i>
                    {/* <i>{!_language ? "Not Available" : _language}</i>, */}
                    </Text>
                    <Text fontSize="lg" fontWeight="semibold" py={[0, 0, 1, 1]}>
                    Directed by {director}
                    </Text>
                    </Box>
                <Spacer />
                    <Box py={4}>
                    <Progress 
                    max={maxVotes} 
                    value={_votes} 
                    colorScheme="green" 
                    rounded="xl" 
                    height="5" 
                    maxW={96} />
                    </Box>
                    {/* <Button
                    leftIcon={<UpDownIcon />}
                    onClick={handleClick}>
                    Change Your Mind ?
                    </Button> */}
                    {
                    _uniqueVotes > 1 ?
                    <Text fontSize="xl" fontWeight="normal"><i>Appears on <b>{_uniqueVotes}</b> user's FilmX Lists!</i></Text>
                    :
                    <Text fontSize="xl" fontWeight="normal"><i>Appears on <b>{_uniqueVotes}</b> user's FilmX List!</i></Text>
                    }

            </GridItem>
            </Grid>

            <Divider my={["4", "4", "8", "8"]}/>

            </>
    );
}