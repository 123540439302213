import { Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, FormControl, FormLabel, Input, ModalFooter, Button, Link, Text, FormHelperText, HStack, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, reset } from "../store/features/auth/authSlice";
import { useNavigate, useParams } from "react-router-dom";


export default function LogIn({isOpen, onOpen, onClose}) {

    let signUp = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);
    
    useEffect(() => {
        if(localStorage.getItem("details")){
            navigate("/verification");
        }

        if(!user) {
            return;
        }

        if(isError) {
            setServerError(message)
        }

        if(isSuccess) {
            onClose();
            navigate('/mylist')
        }

        dispatch(reset())

    }, [user, isError, isSuccess, message, navigate, dispatch, onClose])

    
    const initialValues = {email: ""};
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [serverError, setServerError] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        // console.log(formValues);

        const userData = {
            email: formValues.email,
        };
            dispatch(login(userData));        
        setIsSubmit(true);
    };


    useEffect(() => {
        if(Object.keys(formErrors) === 0 && isSubmit) {
            console.log(formValues);
        }
    }, [formErrors, formValues, isSubmit]);

    const validate = (values) => {
        const errors = {}
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if(!values.email) {
            errors.email = "Email is Required!";
        } else if(!regex.test(values.email)) {
            errors.email = "This is not a valid email"
        }
        return errors;
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>
            Welcome Back!
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <FormControl pb={4}>
                    <FormLabel>Email</FormLabel>
                    <Input 
                    borderColor={formErrors.fName ? "red" : "white"} 
                    type="email" 
                    name="email"
                    placeholder="Email" 
                    value={formValues.email} 
                    onChange={handleChange} /> 
                    <FormHelperText 
                    fontWeight="semibold" 
                    color="red.500">
                    {formErrors.email}
                    </FormHelperText>
                </FormControl>

                <Text>Enter your registered email address and we'll send you a OTP to log in!</Text>

                {/* <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Input 
                    borderColor={formErrors.fName ? "red" : "white"} 
                    type="password" 
                    name="password" 
                    placeholder="Password" 
                    value={formValues.password} 
                    onChange={handleChange} />
                    <FormHelperText fontWeight="semibold" color="red.500">
                    {formErrors.password}
                    </FormHelperText>
                </FormControl> */}
                
                {formErrors
                ?
                <Text pb={2} fontWeight="semibold" color="red.500">{serverError}</Text>
                :
                null
                }
                
            </ModalBody>

            <ModalFooter>
                <HStack spacing={4}>
                {
                isLoading
                ?
                <Spinner />
                :
                <Button 
                colorScheme="blue"
                onClick={handleSubmit}>
                    Log In
                </Button>
                }
                {signUp !== {sign: "sign"}
                ?
                (
                <Link href="/sign-up">
                    <Text
                    color="cyan.400"
                    fontWeight="medium">
                        Not Signed Up?
                    </Text>
                </Link>
                )
                :
                (null)}
                </HStack>
            </ModalFooter>
        </ModalContent>
        </Modal>
    );
};