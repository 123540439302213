import { UpDownIcon } from "@chakra-ui/icons";
import { Box, Flex, Image, Spacer, Center, Text, Button, Divider, Progress, useDisclosure, useToast, Link, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Tooltip, HStack, ModalFooter, Grid, GridItem } from "@chakra-ui/react";
import { useEffect, useState } from "react";
// import isoLangs from "../utils/isolangs.json";
import { useDispatch, useSelector } from "react-redux";
import {createVote} from "../store/features/vote/voteSlice";

export default function ListItem ({
    id,
    colorMode,
    title,
    country,
    language,
    release_date,
    poster_path,
    maxPoints,
    _votes,
    director,
    // writer,
}) {
  

    const getLanguageName = new Intl.DisplayNames(["en"], {type: "language"});
    const languageName = getLanguageName.of(language)

    const getCountryName = new Intl.DisplayNames(["en"], {type: "region"});
    const countryName = getCountryName.of(country)

    // console.log(countryName)


    const { votes, isError, isSuccess, message } = useSelector((state) => state.votes)
    const { isOpen, onOpen, onClose } = useDisclosure();

    const dispatch = useDispatch();
    const toast = useToast();

    const [ allVotes, setAllVotes ] = useState(() =>
        votes.results.map((vote) => {
        return {"movieID": vote.vote.movieId, "votes": vote.vote.votes}
        }
        ));

    // console.log(isError);

    const [ points, setPoints ] = useState(() => Math.sqrt(_votes));
    const [ quadraticPoints, setQuadraticPoints ] = useState(0);
    const [ maxPointsAvailable, setMaxPointsAvailable ] = useState(100);
    const [ selectedID, setSelectedID ] = useState(0);
    const [ votingError, setVotingError ] = useState(false);
    
    const incrementPoints = () => {
        if(points >= 10) {
            return;
        } else {
            setPoints(prevPoints => prevPoints + 1)
        }
    };
    
    const decrementPoints = () => {
        if(points <= 0) {
            return;
        } else {
            setPoints(prevPoints => prevPoints - 1)
        }
    };

    useEffect(() => {
        const quadraticCalc = () => {
            setQuadraticPoints(points * points)
        }

        const updateAllVotes = (id) => {
            setAllVotes(allVotes => allVotes.map(obj => {
                if (obj.movieID == id) {
                    // console.log("yes");
                    return {...obj, "votes": points * points}
                }
                    // console.log("no")
                    return obj;
            }))
        };
            quadraticCalc();
            // console.log(id)
            updateAllVotes(id)
    }, [points, id]);

    const vote = () => {
        const voteData = {
            movieId: id,
            votes: quadraticPoints,    
        };
        // console.log(voteData)
        dispatch(createVote(voteData));
        setQuadraticPoints(0);
        onClose();
        if(isError == false) {
            toast({
                title: "Updating Your List!",
                description: "Our tastes are meant to evolve 🤗",
                status: "success",
                duration: 3000,
                position: "top",
                isClosable: false,
            })
        };
    };

    const allVotesSum = allVotes.reduce((total, currentValue) => total = total + currentValue.votes, 0)
    // console.log(allVotesSum);

    let calcMaxPointsAvailable =  maxPointsAvailable - allVotesSum;
    // console.log(maxPointsAvailable)

    
    function handleClick() {
        setSelectedID(id); 
        onOpen();
    };

    function handleClose() {
        // let calcMaxPointsAvailable = votes.votesAvailable;
        onClose();
    }

    // const random = Math.floor(Math.random() * 3);

    return (
        <>
            <Grid 
            templateColumns='repeat(3, 1fr)'>
            
            <GridItem colSpan={1} justify="right" align="right" mr={[4, 4, 8, 8]}>
                <Image 
                    rounded="md"
                    border="2px" 
                    borderColor="teal.200" 
                    shadow="md" 
                    src= {`https://image.tmdb.org/t/p/w154/${poster_path}`} />
            </GridItem>

            <GridItem colSpan={2}>
                    <Box>
                    <Text fontSize="2xl" fontWeight="semibold" py={[0, 0, 1, 1]}>
                    {title},
                    </Text>
                    <Text fontSize="sm" fontWeight="semibold" py={[0, 0, 1, 1]}>
                    {release_date.slice(0, 4)},
                    </Text>
                    <Text fontSize="md" fontWeight="semibold" py={[0, 0, 1, 1]}>
                    <i>{languageName}</i>
                    {/* <i>{!_language ? "Not Available" : _language}</i>, */}
                    </Text>
                    <Text fontSize="lg" fontWeight="semibold" py={[0, 0, 1, 1]}>
                    Directed by {director}
                    </Text>
                    </Box>
                <Spacer />
                    <Box py={4}>
                    <Progress 
                    max="100" 
                    value={_votes} 
                    colorScheme="green" 
                    rounded="xl" 
                    height="5" 
                    maxW={96} />
                    </Box>
                    <Button
                    leftIcon={<UpDownIcon />}
                    onClick={handleClick}>
                    Change Your Mind ?
                    </Button>

            </GridItem>
            </Grid>

            <Divider my={8}/>

        {/* update list modal */}
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent 
                color= {colorMode === "light" ? "white" : "black.400"}
                bgGradient="linear(to-br, #1A365D, #2B6CB0)"
                border="2px" 
                borderColor="blue.700">
                <ModalHeader>
                    Change your mind ?
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Text pb={2}>
                    We've been there! In fact, we believe it's half the fun! Now, how do you <i>really</i> feel about <b>{title}</b>?
                    <br />
                    <br /><Link href="/FAQ" isExternal ><Tooltip hasArrow label="Read about intensity voting in our FAQs!"><i>Points still confusing?</i></Tooltip></Link>
                    </Text>
                    <Divider shadow="md" pt={4}/>
                    <Center>
                    <Text 
                    fontSize={20} 
                    pb={8} pt={4} 
                    color={points === 0 ? "gray.500" : calcMaxPointsAvailable < 0 ? "gray.500" : "default"}>
                    You're spending {quadraticPoints}/{calcMaxPointsAvailable} points! {calcMaxPointsAvailable < 0 ? "💔" : "💖"}</Text>
                    </Center>
                    <Center>
                    <HStack>
                    
                    <Button
                        // isDisabled={votingError} 
                        bgColor={colorMode === "light" ? "blue.500" : null} 
                        _hover={colorMode === "light" ? {bgColor: "blue.600"} : null}
                        mr={1} 
                        onClick={decrementPoints}>
                        {points >= 5 ? "🧊 -" : "-"}
                    </Button>
                        
                        <Button
                        variant="ghost"
                        pointerEvents="none"
                        bgColor={colorMode === "light" ? "white.500" : null} 
                        // _hover={colorMode === "light" ? {bgColor: "blue.600"} : null}
                        mr={1}>
                        {points}
                        </Button>

                    <Button 
                        // isDisabled={votingError}
                        bgColor={colorMode === "light" ? "blue.500" : null} 
                        _hover={colorMode === "light" ? {bgColor: "blue.600"} : null}
                        ml={1}
                        onClick={incrementPoints}>
                        {points === 0 ? "+" : points <= 3 ? "+ ⚡" : points <= 8 ? "+ 🔥" : points <=10 ? "+ 💥" : "+" }
                    </Button>
                    </HStack>
                    </Center>

                </ModalBody>
                <ModalFooter>
                    <Text 
                    display={calcMaxPointsAvailable < 0 ? "inline" : "none"}
                    pr={4}
                    fontWeight="semibold">
                    You don't have enough points for that. 😔</Text>
                    <Text 
                    display={points === 0 ? "inline" : "none"}
                    pr={4}
                    fontWeight="semibold">
                    You're sure want to remove {title} from your list?</Text>
                    <Button isDisabled={calcMaxPointsAvailable < 0 ? true : false} type="submit" bgColor={"green.500"} mr={3} onClick={vote}>
                        {points === 0 ? "Remove" : "Update"}
                    </Button>
                    <Button bgColor={"red.500"} color={colorMode === "light" ? "black" : "black"} onClick={handleClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>

            </>
    );
}