import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Center, Flex, Heading, Link, Text, VStack } from "@chakra-ui/react";
import { checkOnline } from "../utils/CheckOnline";


export default function FAQ(colorMode) {

  checkOnline();

  return (
    <Box maxW={["99%", "99%", "75%", "75%"]} margin={"auto"}>
      {/* <Center mt={20} mb={20} > */}
        <VStack mt={20} align="center" justify="center" >
          <Box px={4}>
          <Heading>Frequently Asked Questions</Heading>
          <Text fontSize="lg" mt={4}>
          You have questions. We attempt to have answers.</Text>
          </Box>
        <Box>
        <Box maxW={["99%", "99%", "100vw", "100vw"]} align="center" justify="center" margin={"auto"}>
        <Accordion allowToggle allowMultiple mt={8} align="left">

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="semibold">
                  1: What is Intensity Voting?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            Intensity Voting (which may be a term we made up), also called 
            "Plural Voting" or "Quadratic Voting", is radical mechanism 
            for group decision-making outlined by Eric Posner and Glen Weyl 
            in their book "<Link color={colorMode === "dark" ? "cyan.500" : "blue.500"} isExternal
            href="https://press.princeton.edu/books/hardcover/9780691177502/radical-markets">
            <b>Radical Markets</b></Link>" (2018).<br />
            <br />
            The crux of the idea is that our traditional One-Person-One-Vote (OPOV) 
            system allows for what some thinkers have called the "Tyranny of 
            the Majority". The results of these votes can leave out the voice 
            of a passionate and well-informed minority and favor majority groups 
            who might be less passionate or motivated towards the issues at hand.<br />
            <br />
            Quadratic Voting (QV), or Intensity Voting as we're calling it, in simple 
            terms does away with OPOV and instead gives every individual a budget of 
            credits or "points". They can now vote on a handful of issues by spending 
            their points to indicate the <i>intensity</i> of their desire to see a particular
            outcome win. But there's a catch...<br />
            <br />
            The more points you spend on one thing, the more expensive it becomes. What that 
            means is that in order for you to communicate your passion for a particular issue, 
            or film in the case of FilmX, it forces you to truly consider how much you're willing
            to spend for it.<br />
            <br />
            The "Quadratic" in QV is aptly named. The progressive spend of your points rises in 
            a <i>quadratic</i> manner. Essentially, if you spend 1 point it's worth exactly that: 
            1 point. But if you want to spend more, the amount is squared. So the next number you 
            can spend is 4, the square of 2. And then 9, which is the square of 3 and so on...<br />
            <br />
            We won't lie, it takes a second to wrap your head around the idea. But we're hoping that 
            through using FilmX you'll be able to get the hang of it.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="semibold">
                  3: How are the points calculated, again?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            It helps to think of casting votes. 1 vote, 2 votes, 3 votes and so on. Now, in order to cast 
            those votes you have to spend points. The <i>cost</i> of casting a vote is the <u>square</u> of the 
            number of votes you want to cast. <br />
            <br />
            On FilmX everyone gets 100 points. That means the maximum 
            amount of "votes" you can cast for your most intensely liked film is 10 because 10<sup>2</sup>  
            {" "}or 10 x 10 is 100. You have to now wonder, are you okay with your list only having one film? 
            Do you really like that film that much over all the other films you love? That's the crux of the math.
            It makes you to think in terms of how you really feel about something as opposed to just going along 
            with what the most popular films or opinions of the day are.<br />
            <br />
            We hope this helps? If it doesn't then do leave us some {" "}
            <Link 
            color={colorMode === "dark" ? "cyan.500" : "blue.500"} 
            href="/feedback" isExternal>
            feedback
            </Link> and we'll try and do a better job of simplifying this further! 
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="semibold">
                  4: What is the Discovery Palette?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            Our idea with searching for films was that we didn't want to show any predecided 
            "popular films" or "recent releases". But we obviously still wanted you to be able 
            to search for the films that you love. The Discovery Palette is named as such because 
            we're hoping it can feel like a choice palette of your unique film searches. Colours 
            only you could have chosen that will go on to make up your evolving canvas of intensely 
            liked films.<br />
            <br />
            <br />
            tl;dr: We were trying to be poetic.

            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="semibold">
                  5: Tell me more about Streaming Availability?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            Streaming information is provided to us by <Link color={colorMode === "dark" ? "cyan.500" : "blue.500"} href="https://www.justwatch.com/" isExternal>JustWatch</Link>. 
            We, therefore, don't provide links to the streaming services themselves but instead to JustWatch where you can 
            find the appropriate links. <br/>
            <br/>
            Also, we currently only provide streaming information for India. For other countries you can find that information through JustWatch itself.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="semibold">
                  6: Why did you build FilmX?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            Lekh-Haq wants to try and create things that can help us approach solutions 
            for the problems we see all around us. With film recommendation apps and Algorithm-driven 
            streaming services we feel like there might be a gap in our ability to really 
            think about the films we <i>truly</i> love and the ones that have us hyped for a bit 
            and fade until the next viral trend.<br />
            <br />
            We built FilmX because we wanted to see if perhaps other people felt the same way. 
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="semibold">
                  7: What's next for FilmX?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
           We have no idea! This is just a small prototype. If anything, it's an example of slow social media. 
           We don't have ways to keep you coming back. And if we did, it would be slightly counter-productive.<br />
           <br />
           If we find that enough people enjoy making their lists and sharing them with other people, we would love to 
           start building more features that you believe you'd want to see here. <br />
           <br />A way to leave short reviews for the films in your list. A way to connect with other people who have 
           the same films on their list, perhaps.<br />
           <br /> 
           Who knows.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="semibold">
                  8: I have thoughts on how to make FilmX better!
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            That's amazing! We'd love to hear it.<br />
            <br />
            You can send us any thoughts or feedback by clicking {" "}
            <Link
            color={colorMode === "dark" ? "cyan.500" : "blue.500"} 
            href="/feedback" isExternal>
            <u>here</u>
            </Link>. 
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="semibold">
                  9: I don't like something about FilmX...
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            We're sorry to hear that. We'd like to do better!<br />
            <br />
            You can send us feedback by clicking {" "}
            <Link
            color={colorMode === "dark" ? "cyan.500" : "blue.500"} 
            href="/feedback" isExternal>
            <u>here</u>
            </Link>.
            </AccordionPanel>
          </AccordionItem>

        </Accordion>
        </Box>
        </Box>

        </VStack>
      {/* </Center> */}

    </Box>
  )
}
