import { Box, Button, Center, Container, Heading, HStack, PinInput, PinInputField, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { verifyOTP, reset, resendOtp } from '../store/features/auth/authSlice';

export default function OtpVerification() {

    let navigate = useNavigate();
    let dispatch = useDispatch();

    const {user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth )
    const [ otp, setOtp ] = useState(""); 
    const [serverError, setServerError] = useState("")

    const _email = JSON.parse(localStorage.getItem("details")) || JSON.parse(localStorage.getItem("user"));
    // console.log(_email.email);

    
    useEffect(() => {
        // if(isError) {
        //     setServerError(message);
        // }

        setServerError(isError ? message : "");

        if(isSuccess) {
                // console.log("Success");
                localStorage.removeItem("details")
                navigate("/myList");
                // window.location.reload();
            }

        // dispatch(reset());

    }, [user, isError, isSuccess, message, navigate]);

    console.log(message?.message)

    const handleChange = (event) => {
        setOtp(event);
    };
    
    const onSubmit = async (e) => {
        const userData = {
            email: _email.email,
            otp: otp,
        }
        // console.log(otp);
        dispatch(verifyOTP(userData))
    };

    const handleClick = () => {
        console.log("resend otp email")
        const userData = {
            email: _email.email,
        }
        dispatch(resendOtp(userData))
    };
    
  return (
    <Container mt={24} p={8} rounded="lg" border="1px" maxW={["md", "md", "lg", "lg"]}>
    <VStack>
        <Heading fontSize={32} pb={2}>
            OTP Verification!
        </Heading>
        <Text pb={12}>Your OTP will expire in 1 hour!<br/>Remember to check your Spam and Promotions folder!</Text>        
        </VStack>
        <Box align="center" justify="center">
        <Center>
        <HStack spacing="4">
            <PinInput size="lg" type="number" value={otp} onChange={handleChange} otp >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
            </PinInput>
        </HStack>
        </Center>
        <Button w={60} variant="solid" colorScheme="green" mt="4" onClick={onSubmit}>Verify</Button>
        </Box>
        <Text color={"red.500"} align="center" pt={2} justify="center" >{serverError}</Text>
        {
            message?.message === "Email resent!" ?
            <Text color={"cyan.400"} align="center" pt={2} justify="center" >Email resent!</Text>
            : null
        }
        <Text textAlign="center" pt="16">
            Can't see the email? <Button size="lg" variant="link" color="cyan.400" onClick={handleClick}>Resend Email</Button>
        </Text>

    </Container>
  );
};