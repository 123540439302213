import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "./authService";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
    user: user ? user : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
}

//Register User
export const register = createAsyncThunk("auth/register", async (user, thunkAPI) => {
    try {
        return await authService.register(user)
    } catch (error) {
        const message = 
        (error.response && 
            error.response.data && 
            error.response.data.message) 
            || error.message 
            || error.toString()
        return thunkAPI.rejectWithValue(message);
    }
});


//Login User
export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
    try {
        return await authService.login(user)
    } catch (error) {
        const message = 
        (error.response && 
            error.response.data && 
            error.response.data.message) 
            || error.message 
            || error.toString()
        return thunkAPI.rejectWithValue(message);
    }
});

//Verify OTP
export const resendOtp = createAsyncThunk("auth/resendOTP", async(user, thunkAPI) => {
    try {
        return await authService.resendOtp(user)
    } catch (error) {
        const message = 
        (error.response && 
            error.response.data && 
            error.response.data.message) 
            || error.message 
            || error.toString()
        return thunkAPI.rejectWithValue(message);
    }
})


//Verify OTP
export const verifyOTP = createAsyncThunk("auth/verifyOTP", async(user, thunkAPI) => {
    try {
        return await authService.verifyOTP(user)
    } catch (error) {
        const message = 
        (error.response && 
            error.response.data && 
            error.response.data.message) 
            || error.message 
            || error.toString()
        return thunkAPI.rejectWithValue(message);
    }
})


//logout
export const logout = createAsyncThunk("auth/logout", async () => {
    await authService.logout()
});


export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ""
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
            .addCase(register.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.user = null;
            })
            .addCase(verifyOTP.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(verifyOTP.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.user = action.payload;
            })
            .addCase(verifyOTP.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.user = null;
            })
            .addCase(login.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.user = action.payload
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.user = null
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null;
            })
            .addCase(resendOtp.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(resendOtp.fulfilled, (state, action) => {
                state.isLoading = false;
                state.message = action.payload;
            })
            .addCase(resendOtp.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.user = null
            })

            
    },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;