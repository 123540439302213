import { Box, Center, Container, Divider, Image, List, ListIcon, ListItem, SimpleGrid, Stack, Text, Wrap, WrapItem, Link } from "@chakra-ui/react";
// import { Link } from "react-router-dom";
import Socials_Logo_Black from "../assets/Socials_Logo_Black.svg"

export default function Footer({colorMode}) {

    const currentYear = new Date().getFullYear();

  return (
    <>
    <Divider pb={20} />
    <Box p={12} bgColor={colorMode === "dark" ? "blackAlpha.500" : "blackAlpha.50"}>
        
        {/* RESPONSIVE IMAGE */}
        <Box display={["inline", "inline", "none", "none"]}>
        <Center>
        <Box boxSize={36} mb={4}>
            <Image 
            src={Socials_Logo_Black} 
            alt="Lekh-Haq Logo" 
            filter={colorMode === "dark" ? "invert(1)" : null}
            />
        </Box>
        </Center>
        </Box>
        {/* END OF RESPONSIVE IMAGE */}

        <Box>
        <Center>
        <Wrap>
        <WrapItem>
        <Box display={["none", "none", "inline", "inline"]}>
        <Box boxSize={36} mr={4}>
            <Image 
            src={Socials_Logo_Black} 
            alt="Lekh-Haq Logo" 
            filter={colorMode === "dark" ? "invert(1)" : null}
            />
        </Box>
        </Box>
        </WrapItem>
        
        <WrapItem>
            <Container p={4}>
            <Text fontWeight={"bold"} fontSize="21" pb={2}>
                Helpful Links
            </Text>
            <List spacing={4}>
                <ListItem><Link isExternal="true" href="/FAQ">FAQ</Link></ListItem>
                <ListItem><Link isExternal="true" href="/feedback">Feedback</Link></ListItem>
            </List>
            </Container>
        </WrapItem>

        <WrapItem>
            <Container p={4}>
            <Text fontWeight={"bold"} fontSize="21" pb={2}>
                Social
            </Text>
            <List spacing={4}>
            <ListItem><Link isExternal="true" href="https://www.instagram.com/lekh_haq">Follow us on Instagram</Link></ListItem>
            
            
            <ListItem><Link isExternal="true" href="https://twitter.com/LekhHaq">Follow us on Twitter</Link></ListItem>
            <ListItem><i>Write to us at: <br />hello[at]lekhhaq[dot]com</i></ListItem>
            </List>
            </Container>
        </WrapItem>
        
        <WrapItem>
            <Container p={4}>
            <Text fontWeight={"bold"} fontSize="21" pb={2}>
                Legal
            </Text>
            <List spacing={4}>
            <ListItem><Link isExternal="true" href="/terms-of-service">Terms of Service</Link></ListItem>                
            <ListItem><Link isExternal="true" href="/privacy-policy">Privacy Policy</Link></ListItem>
            </List>
            </Container>
        </WrapItem>

        </Wrap>

            </Center>
        </Box>
        
        <Divider pt={20} />

        <Box>
            <Text
            pointerEvents="none"
            textAlign="center" 
            fontWeight="semibold"
            pt={8}>
            LEKH-HAQ • {currentYear}
            </Text>
        </Box>
        
    </Box>
    </>
  )
}
