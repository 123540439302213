import { useEffect, useState } from "react";
import { Center, Spinner, Box, Text, Divider, SimpleGrid, Button, HStack, Link } from "@chakra-ui/react";
import { ChevronUpIcon } from "@chakra-ui/icons";
import { motion } from "framer-motion";
import SearchBox from "./SearchBox";
import SearchUtils from "../utils/searchUtils";
import SearchResultCard from "./SearchResultCard";
import { useSelector } from "react-redux";
import { checkOnline } from "../utils/CheckOnline";


export default function DiscoveryPalette({
    colorMode,
   }) {

    checkOnline();

    const {
      handleClear, 
      searchTerm, 
      setSearchTerm,
      searchType,
      setSearchType,
      searchResults,
      setSearchResults,
      isSearching,
      setIsSearching, 
      loaded,
      setLoaded} = SearchUtils();  

    const { votes, isError, isSuccess, message } = useSelector((state) => state.votes)

    // console.log(votes.results)
    
    const [ userList, setUserList] = useState([])
    
    useEffect(() => {
      if (votes.results) {
        setUserList(() =>
          votes.results.map((vote) => {
          return vote.vote.movieId
          }
        ));
      } else {
        return;
      }
    }, [votes])

    // console.log(userList)

    const renderSearchResultCard = (searchResults) => {
        return (
          <Box
            key={searchResults.id}
            as={motion.div}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}>
          <Center>
          <SearchResultCard 
              key={searchResults.id}
              id={searchResults.id}
              title={searchResults.title}
              poster={searchResults.poster_path}
              overview={searchResults.overview}
              searchType={searchType}
              setSearchTerm = {setSearchTerm}
              userList = {userList}
              colorMode = {colorMode}
          />
          </Center>
          </Box>
        );
    }

    return (
        <>
            <Box 
            pt={10}
            pl={10}
            pr={10}
            pb={2}>
            <HStack>
            <Text
            fontSize="4xl"
            fontWeight='bold'>
                Discovery Palette 
            </Text>
                
            <Link href="/FAQ" isExternal="true" style={{textDecoration: "none"}}>
            <Text fontSize={20} color="blackAlpha.50" _hover={colorMode === "light" ? {color: "blue.500", fontWeight: "bold"} : {color:"white", fontWeight: "semibold"}}>🎨?</Text>
            </Link>

            </HStack>

            <Divider my={"4"}/>
              <Box w={["200px", "200px", "800px", "400px"]}>
              <SearchBox 
                colorMode={colorMode}
                searchResults={searchResults}
                setSearchResults={setSearchResults}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                searchType={searchType}
                setSearchType={setSearchType}
                isSearching={isSearching}
                setIsSearching={setIsSearching}
                handleClear={handleClear}
                loaded={loaded} 
                setLoaded={setLoaded} />
              </Box>

            <Divider my={"4"}/>

            {searchTerm === ""
            ?
            <Box>
            <Text mt={4} fontWeight="medium"><i>Search for films to add your list and they'll appear here!</i></Text> 
            <Center p={4}>
            <ChevronUpIcon />
            </Center>
            </Box>
            :
            <>
            <Text my={8} fontWeight="medium" fontSize="2xl"><i>Click on a film to add it to your list!</i></Text>
            <SimpleGrid minChildWidth="300px" spacing="10px">
                {isSearching === false && searchResults
                ?
                searchResults.slice(0, 25).map(renderSearchResultCard, searchResults.id)
                :
                <Center>
                <Spinner size="xl" />
                </Center>
                }
            
            </SimpleGrid>
            <Button display={searchTerm === "" ? "none" : "inline"} mt={4} size={"sm"} variant="outline" color="red.600" onClick={handleClear}>
            Clear Search
            </Button>
            </>
            
            }
            <Divider my={"4"}/>
            </Box>
        </>
    );
};