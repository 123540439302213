import axios from "axios";
import { CloseIcon, SearchIcon, SettingsIcon } from "@chakra-ui/icons";
import { Box, Input, InputGroup, InputLeftElement, IconButton, InputRightElement, Menu, MenuButton, MenuList, MenuOptionGroup, MenuItemOption, Button, HStack } from "@chakra-ui/react";
import { useEffect } from "react";
import useDebounce from "../utils/useDebounceHook";
import { API_BASE_URL } from "../config";

export default function SearchBox({
    colorMode,
    searchTerm,
    setSearchTerm,
    setSearchResults,
    searchType,
    setSearchType,
    isSearching, 
    setIsSearching,
    handleClear,
    setLoaded,
}) {

    const debouncedSearchTerm = useDebounce(searchTerm, 500)

    useEffect(() => {
        async function fetchSearchData() {
            if(!searchTerm){
                return;
            };
            if(debouncedSearchTerm) {
                setIsSearching(true);
                await axios.get(`${API_BASE_URL}/tmdb/movies?type=${searchType}&query=${searchTerm}`)
                .then((response) => {
                    // console.log(response.data.results);
                    setSearchResults(response.data.results);
                    setIsSearching(false);
                    if(searchType === "person") {
                        // console.log(response.data)
                        setSearchResults(response.data);
                        setLoaded(true);
                    }
                }).catch((err) => {
                    console.log(err);
                    setSearchResults([]);
                    setIsSearching(false)
                })
            }
        }

        fetchSearchData();
        
      }, [searchTerm, searchType, setLoaded, debouncedSearchTerm, setIsSearching, setSearchResults]);  

    return (
        
        <HStack>
        <Box mr={6} bgColor={colorMode === "dark" ? "gray.500" : "gray.100"}>
        <InputGroup 
        rounded="lg">
            <InputLeftElement
            rounded="lg"
            pointerEvents="none"
            children={<SearchIcon />} />
            <InputRightElement
            rounded="lg"
            display={!searchTerm ? "none" : "inline"}
            p={2}
            boxSize="2.5em"
            children={<CloseIcon onClick={handleClear}/>} />
            <Input 
            rounded="lg"
            w={"50vw"}
            type="text" 
            placeholder="Search..."
            value = {searchTerm} 
            onChange={(e) => 
                {if(searchTerm === "") {
                setSearchTerm(e.target.value)
                setSearchResults([])
                } else {
                setSearchTerm(e.target.value)}}} />
        </InputGroup>
        </Box>
        <Box>
        <Menu>
            <MenuButton as ={Button} display={["none", "none", "inline", "inline"]} rightIcon={<SettingsIcon />}>
                {searchType.charAt(0).toUpperCase() + searchType.slice(1)}
            </MenuButton>
            <MenuList>
                <MenuOptionGroup defaultValue="movie" title="Filter" type="radio">
                    <MenuItemOption value="movie" onClick={() => setSearchType("movie")}>
                    Movie
                    </MenuItemOption>
                    <MenuItemOption value="Person" onClick={() => setSearchType("person")}>
                    Person
                    </MenuItemOption>
                </MenuOptionGroup>
            </MenuList>
        </Menu>
        <Menu>
            <MenuButton as ={IconButton} display={["inline", "inline", "none", "none"]}>
            <SettingsIcon />
            </MenuButton>
            <MenuList>
                <MenuOptionGroup defaultValue="person" title="Filter" type="radio">
                    <MenuItemOption value="movie" onClick={() => setSearchType("movie")}>
                    Movie
                    </MenuItemOption>
                    <MenuItemOption value="Person" onClick={() => setSearchType("person")}>
                    Person
                    </MenuItemOption>
                </MenuOptionGroup>
            </MenuList>
        </Menu>
        </Box>
        </HStack>
    );
};