import axios from "axios"
import { API_BASE_URL } from "../../../config";

//register user
const SIGNUP_URL = `${API_BASE_URL}/sign-up`
// const SIGNUP_URL = `http://localhost:5000/sign-up`
const register = async(userData) => {
    const response = await axios.post(SIGNUP_URL, userData)
    // console.log(response)
    if(response.data) {
        // console.log(response);
        localStorage.setItem("details", JSON.stringify({
            email: response.data.email
        }))
    }
    return response.data;
};


//Verify OTP
const VERIFYOTP_URL = `${API_BASE_URL}/verify`
const verifyOTP = async(userData) => {
    const response = await axios.post(VERIFYOTP_URL, userData)
    if(response.data) {
        // console.log(response.data)
        localStorage.setItem("user", JSON.stringify({
            name: response.data.name,
            email: response.data.email,
            username: response.data.username,
            token: response.data.token,
        }))
    }
    return response.data;
};


//logout user
const logout = () => {
    localStorage.removeItem("user");
};

//login user
const LOGIN_URL = `${API_BASE_URL}/login`
const login = async(userData) => {
    const response = await axios.post(LOGIN_URL, userData)
    if(response.data) {
        // console.log(response.data);
        // console.log(response.data.data.email);
        localStorage.setItem("details", JSON.stringify({
            email: response.data.data.email
        }))
    }
    return response.data;
};

//login user
const RESENDOTP_URL = `${API_BASE_URL}/resendOtp`
const resendOtp = async(userData) => {
    const response = await axios.post(RESENDOTP_URL, userData)
    if(response.data) {
        // console.log(response.data);
        // console.log(response.data.data.email);
        localStorage.setItem("details", JSON.stringify({
            email: response.data.data.email
        }))
    }
    return response.data;
};

 
const authService = {
    register,
    logout,
    login,
    verifyOTP,
    resendOtp,
}

export default authService;