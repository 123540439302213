import { Flex, Button, IconButton, Avatar, Link, Box, Image, HStack, useDisclosure, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, Text } from "@chakra-ui/react";
import { SunIcon, MoonIcon, HamburgerIcon, CloseIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import Socials_Logo_Black from "../assets/Socials_Logo_Black.svg"
import { useEffect, useState } from "react";
import { logout, reset } from "../store/features/auth/authSlice";
import Login from "./Login";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export default function NavBar({
  colorMode,
  toggleColorMode,
  }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user, isError, message } = useSelector((state) => state.auth);
    const [currentUser, setCurrentUser] = useState({});
    
    useEffect(() => {
      if(isError) {
        // localStorage.removeItem("user");
        // console.log(message)
      }

      setCurrentUser(user);
    }, [user, isError])
    
    const handleLogOut = () => {
      dispatch(logout());
      dispatch(reset());
      navigate("/");
      onClose();
    };


    const [ display, changeDisplay ] = useState("none");
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { isOpen: isOpenLogin, onOpen: onOpenLogin, onClose: onCloseLogin } = useDisclosure();

    const name = !user ? "" : user.name;

    return (
      <Box
        mb="4rem">
      <HStack>
      <Flex
      pos="fixed"
      top="1.5rem"
      align="center"
      >
      <Flex>
      <Box>
      <Link href="/">
        <Image src={Socials_Logo_Black} boxSize="60px" ml="1rem" mr="2rem" filter={colorMode === "dark" ? "invert(1)" : "none"} />
        </Link>
      </Box>
      <Flex>
        <Flex
          pos="fixed"
          top="1rem"
          right="1rem"
          align="center">
        <Flex
          display={["none", "none", "flex", "flex"]}>
          <Link href="/mylist" style={{textDecoration: "none"}}>
          <Button
            isDisabled={currentUser ? false : true}
            variant="ghost"
            aria-label="My List"
            my={5}
            w="100%">
            My List
            </Button>
          </Link>
          <Link href="/FAQ" style={{textDecoration: "none"}}>
          <Button
            variant="ghost"
            aria-label="Home"
            my={5}
            w="100%">
            FAQ
            </Button>
          </Link>
          <Link href="/feedback" style={{textDecoration: "none"}}>
          <Button
            variant="ghost"
            aria-label="Home"
            my={5}
            w="100%">
            Feedback
            </Button>
          </Link>
        </Flex>
        
        {/* Responsive Menu */}
        <IconButton mx="1.5rem" mt={["1.15rem", "1.15rem", "0.15rem", "0.15rem"]} isRound icon={colorMode === "dark" ? <SunIcon bgColor="grey.200"/> : <MoonIcon bgColor="grey.500" />} onClick={toggleColorMode} /> 
        <Link href="#">
        {!user ?         
        <Button display={["none", "none", "flex", "flex"]} fontWeight="semibold" color="cyan.400" textDecoration="none" onClick={onOpenLogin}>Login</Button>
        :
        <Avatar display={["none", "none", "flex", "flex"]} bg="cyan.600" color="white" name={name} onClick={onOpen} />}
        </Link>
        <IconButton
          mt="1.15rem"
          aria-label="Open Menu"
          size="lg"
          mr={2}
          icon={<HamburgerIcon />} 
          display = {["flex", "flex", "none", "none"]}
          onClick={() => changeDisplay("flex")}
          />
        </Flex>
        <Flex
          w="100vw"
          bgColor={colorMode === "light" ? "gray.50" : "gray.800"}
          zIndex={100}
          h="100vh"
          pos="fixed"
          top="0"
          left="0"
          overflowY="auto"
          flexDir="column"
          display={display}>
        <Flex justify="flex-end">
          <IconButton
            mt={6}
            mr={6}
            aria-label="Close Menu"
            size="md"
            icon={<CloseIcon />}
            onClick={() => changeDisplay("none")} />
        </Flex>
        <Flex
          flexDir="column"
          align="center">
        {!user ?
        <Button  
        fontWeight="semibold" fontSize="28" p="6" color="cyan.400" textDecoration="none" onClick={() => {onOpenLogin(); changeDisplay("none")}}>Login</Button>
        :
        <Avatar boxSize="16" mb="4" bg="cyan.600" color="white" name={name} onClick={onOpen}/>
        }
        <Link href="/mylist" style={{textDecoration: "none"}}>
            <Button
              isDisabled={user ? false : true}
              variant="ghost"
              aria-label="My List"
              my={5}
              fontSize="28"
              w="100%">
              My List
              </Button>
            </Link>
            <Link href="/FAQ">
            <Button
              fontSize="28"
              variant="ghost"
              aria-label="FAQ"
              my={5}
              w="100%">
              FAQ
              </Button>
            </Link>
            <Link href="/feedback">
            <Button
              fontSize="28"
              variant="ghost"
              aria-label="Feedback"
              my={5}
              w="100%">
              Feedback
              </Button>
            </Link>
        </Flex>
        </Flex>
        </Flex>
        </Flex>
      </Flex>
      </HStack>


      <Drawer
      placement="bottom"
      size="xl"
      onClose={onClose}
      isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader 
          borderBottomWidth="1px">
            {`Hello, ${user ? user.name : ""}!`}
          </DrawerHeader>
          <DrawerBody
          minH="400px">
          <Text py={4} >Would you like to log out?</Text>
            <Button
            mb={4} 
            rightIcon={<ArrowForwardIcon />}
            onClick={() => {handleLogOut(); changeDisplay("none")}}>
              Log Out
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>


      <Login 
        isOpen={isOpenLogin}
        onClose={onCloseLogin}      
      />


      </Box>

    );
}