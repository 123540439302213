import React from 'react'
import { Box, Button, ButtonGroup, Stack, Center, Heading, Link } from '@chakra-ui/react'
import { useToast } from "@chakra-ui/react";
import { checkOnline } from '../utils/CheckOnline';

function NotFound( { errorMessage }) {

  const toast = useToast();
  checkOnline();

  return (
    <>
    <Box my={36} mx="10">
    <Center>
        <Heading align="center" justify="center" size="lg" mb={20}>{errorMessage}</Heading>
    </Center>
    <Box justify="center" align="center" rounded="md" p="8" mt={20} bgColor="whiteAlpha.50">
        <Heading pb="8" size="md">Would you like to make a list?</Heading>
        <Box display={["none", "none", "inline", "inline"]}>
        <ButtonGroup>
        <Link href="/sign-up" isExternal style={{textDecoration: "none"}} ><Button variant="solid" colorScheme="green">Yes, Sign me up!</Button></Link>
        <Link href="/" isExternal style={{textDecoration: "none"}} ><Button variant="outline" borderColor="cyan.500">Learn More</Button></Link>
        </ButtonGroup>
        </Box>
        <Box display={["inline", "inline", "none", "none"]}>
        <Stack spacing={4}>
        <Link href="/sign-up" isExternal style={{textDecoration: "none"}} ><Button variant="solid" colorScheme="green">Yes, Sign me up!</Button></Link>
        <Link href="/" isExternal style={{textDecoration: "none"}} ><Button variant="outline" borderColor="cyan.500">Learn More</Button></Link>
        </Stack>
        </Box>
    </Box>
    </Box>
            </>    
  )
}

export default NotFound;