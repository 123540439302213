import {
  Box,
  Button,
  Container,
  Divider,
  Heading,
  Image,
  VStack,
  Text,
  Center,
  Wrap,
  WrapItem,
  SimpleGrid,
  Flex,
  useDisclosure,
  Spinner,
  Spacer,
  IconButton,
  keyframes,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Login from "./Login";
import Laptop_Mockup from "../assets/Laptop_Mockup.png";
import Mobile_Mockup from "../assets/Mobile_Mockup.png";
import TMDB_Logo from "../assets/TMDB_Logo.svg";
import axios from "axios";
import LandingPageListItem from "./LandingPageListItem";
import { ArrowForwardIcon } from "@chakra-ui/icons";

export default function LandingPage({ colorMode }) {
  const navigate = useNavigate();

  const { user, isError } = useSelector((state) => state.auth);

  const {
    isOpen: isOpenLogin,
    onOpen: onOpenLogin,
    onClose: onCloseLogin,
  } = useDisclosure();

  const learnMore = useRef(null);

  const goToLearnMore = () => {
    learnMore.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const [allFilms, setAllFilms] = useState([]);

  const [loading, setLoading] = useState(false);

  const getFilms = async () => {
    setLoading(true);
    const result = await axios
      .get(`${process.env.REACT_APP_API_URL}/movies/allVotes`)
      .then(
        (response) => {
          // console.log("something");
          // console.log(response.data.votes);
          setLoading(false);
          setAllFilms(response.data.votes);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    getFilms();
  }, [])

  // console.log(allFilms);

  const animation = keyframes`
    from {
      opacity: 1
    }
    to {
      opacity: 0
    }
    `;
    
    return (
      <>
        <Container maxW={["90vw"]} pt={["20", "20", "28", "28"]}>
          <SimpleGrid columns={[1, 1, 2]}>
            <Box
              pb={[8, 8, 0, 0]}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Center>
                <Box maxW={96}>
                  <Heading pb={3} size="2xl">
                    Let's Get Real About The Films We Love
                  </Heading>
                  <Text pb={4} fontSize={20}>
                    Build, Maintain and Share a List of your most{" "}
                    <em>intensely liked</em> films with the world.
                  </Text>
                  <Flex
                    align={["center", "center", "left", "left"]}
                    justify={["center", "center", "left", "left"]}
                  >
                    {!user ? (
                      <Button
                        bgColor="cyan.400"
                        color={colorMode === "light" ? "grey.700" : "black"}
                        my={4}
                        py={8}
                        px={2}
                        onClick={() => navigate("/sign-up")}
                      >
                        Build your list!
                      </Button>
                    ) : (
                      <Button
                        bgColor="green.400"
                        color={colorMode === "light" ? "white" : "white"}
                        my={4}
                        py={8}
                        px={2}
                        onClick={() => navigate("/mylist")}
                      >
                        Go To Your list!
                      </Button>
                    )}
                    <Button py={8} px={7} ml={4} onClick={goToLearnMore}>
                      Learn More
                    </Button>
                  </Flex>
                </Box>
              </Center>
            </Box>

            {/* <Box display={["inline", "inline", "none", "none"]}>
            <Image src={Mobile_Mockup}></Image>
          </Box> */}

            <Box display={["none", "none", "inline", "inline"]}>
              <Image src={Laptop_Mockup}></Image>
            </Box>
          </SimpleGrid>

          <Divider my={["6", "6", "12", "12"]} />

          <Flex flexDirection={"column"} mb="8">
            <Heading
              fontSize={["3xl", "3xl", "4xl", "4xl"]}
              m="auto"
              fontWeight={"bold"}
              p={"2"}
            >
              Most Intensely Liked Films
            </Heading>
            <Text
              justifyContent={"center"}
              fontSize={["lg", "lg", "xl", "xl"]}
              fontWeight="medium"
              alignItems={["center"]}
              px={"2"}
              m={"auto"}
              my={"2"}
            >
              <Box
                display={"inline-flex"}
                animation={`${animation} infinite 1.2s ease`}
              >
                🟢
              </Box>{" "}
              Real-time data from across FilmX lists!
            </Text>
            <Flex
              onClick={() => navigate("/sign-up")}
              fontSize={["md", "md", "lg", "lg"]}
              fontWeight="semibold"
              m="auto"
              my={["1", "1", "4", "4"]}
              p="2"
              px={"5"}
              py={["3", "3"]}
              bgColor={"cyan.400"}
              rounded={"2xl"}
              maxW={["98%", "98%", "75%", "75%"]}
              textColor={"blackAlpha.800"}
            >
              <Box>
                Don't see your most intensely liked films here? Start your own
                list and have your say!{" "}
                <Link to="/sign-up" fontWeight={"bold"}>
                  <u>
                    <i>Sign up now</i>
                  </u>
                </Link>{" "}
                &rarr;
              </Box>
            </Flex>
          </Flex>
          {
          loading ? 
          <>  
          <Center>
            <Box h="100%">
              <Spinner my={"48"} size={"xl"} />
            </Box>
          </Center>
          </>
          :
          <Box>
            {allFilms.map((film) => {
              {/* console.log(film); */}
              const directorSearch = film.movieDetails.credits.crew;
              const directorSearch2 = directorSearch.find(
                (obj) => obj.job === "Director"
              );
              const director =
                directorSearch2 === undefined
                  ? "Not Available"
                  : directorSearch2.name;
              const maxVotes = allFilms[0].votes;

              return (
                <LandingPageListItem
                  key={film.movieId}
                  id={film.movieId}
                  colorMode={colorMode}
                  title={film.movieDetails.title}
                  country={
                    film.movieDetails.production_countries[0]
                      ? film.movieDetails.production_countries[0].iso_3166_1
                      : ""
                  }
                  language={film.movieDetails.original_language}
                  release_date={film.movieDetails.release_date}
                  poster_path={film.movieDetails.poster_path}
                  maxVotes={maxVotes}
                  _votes={film.votes}
                  _uniqueVotes={film.uniqueVotes}
                  director={director}
                />
              );
            })}
          </Box>
           }


          <Flex
            justifyContent="center"
            alignItems="center"
            pb={12}
            mt={"16"}
            ref={learnMore}
          >
            <Heading size="lg">The Idea Is Simple</Heading>
          </Flex>

          <Wrap justify="center" spacing={4}>
            <WrapItem>
              <Box maxW={80} borderBottomWidth="1px" rounded="lg">
                <Box>
                  <Text pl="6" textAlign="left" fontSize={24}>
                    ⭐⭐⭐⭐🤔❓
                  </Text>
                </Box>
                <Box p="6">
                  <Box display="flex" alignItems="baseline">
                    <Text fontSize={20} fontWeight="semibold">
                      Traditional Film Rating systems aren't built to see how{" "}
                      <u>intensely</u> a film is liked <i>over</i> other films.
                      <br />
                      We want to try and fix that.
                    </Text>
                  </Box>
                </Box>
              </Box>
            </WrapItem>

            <WrapItem>
              <Box maxW={80} borderBottomWidth="1px" rounded="lg">
                <Box>
                  <Text pl="6" textAlign="left" fontSize={24}>
                    ❤️🔥😄
                  </Text>
                </Box>
                <Box p="6">
                  <Box display="flex" alignItems="baseline">
                    <Text fontSize={20} fontWeight="semibold">
                      On <b>FilmX</b> you spend points to indicate how intensely
                      you like a particular film.
                      <br />
                      <br />
                      <i>But there's a catch</i>...
                    </Text>
                  </Box>
                </Box>
              </Box>
            </WrapItem>

            <WrapItem>
              <Box maxW={80} borderBottomWidth="1px" rounded="lg">
                <Box>
                  <Text pl="6" textAlign="left" fontSize={24}>
                    ✨🚀🤗
                  </Text>
                </Box>
                <Box p="6">
                  <Box display="flex" alignItems="baseline">
                    <Text fontSize={20} fontWeight="semibold">
                      Every <i>additional</i> point you spend will cost more!
                      <br />
                      <em>
                        <b>
                          How much are you really willing to spend to keep that
                          film in your list?
                        </b>
                      </em>
                    </Text>
                  </Box>
                </Box>
              </Box>
            </WrapItem>
          </Wrap>

          <Box py={20}>
            <Divider />
          </Box>

          <Center>
            <Box h={96} w={["90vw", "90vw", "55vw", "55vw"]}>
              <VStack>
                <Box>
                  <VStack>
                    <Heading fontSize={["3xl", "3xl", "3xl", "3xl"]}>
                      FilmX is in Active Development 🧪
                    </Heading>

                    <Divider />

                    <Box>
                      <Text
                        mb={8}
                        fontWeight="normal"
                        fontSize={["18", "18", "21", "21"]}
                      >
                        Help us build a platform of honest and trustworthy film
                        recommendations?
                      </Text>
                    </Box>
                  </VStack>
                </Box>

                <Button
                  bgColor="cyan.400"
                  color={colorMode === "light" ? "grey.700" : "black"}
                  p={8}
                  size="lg"
                  onClick={() => navigate("/sign-up")}
                >
                  Build Your List!
                </Button>
              </VStack>
            </Box>
          </Center>

          <Divider mb={9} />

          <Flex pb={8} align="right" justify="right">
            <Image src={TMDB_Logo} w={24} mr={3} />
            <Text fontWeight="medium">
              FilmX gets all it's film data from the TMDb API. FilmX is not
              endorsed or certified by TMDb.
            </Text>
          </Flex>

          <Divider mb={4} />

          <Flex align="right" justify="right">
            <Text fontWeight="medium">
              Also, Lekh-Haq has nothing but the utmost love and regard for
              Clyde Mendonca for helping us build this. <br />
              Clyde, you're a gentleman and a savant. <i>Thank you</i>.
            </Text>
          </Flex>
        </Container>

        <Login isOpen={isOpenLogin} onClose={onCloseLogin} />
      </>
    );
  }




// return (
//   <>
//     <Container maxW={["90vw"]} pt={["20", "20", "28", "28"]}>
//       <SimpleGrid columns={[1, 1, 2]}>
//         <Box
//           pb={[16, 16, 0, 0]}
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//         >
//           <Center>
//             <Box maxW={96}>
//               <Heading pb={3} size="2xl">
//                 Let's Get Real About The Films We Love
//               </Heading>
//               <Text pb={4} fontSize={20}>
//                 Build, Maintain and Share a List of your most{" "}
//                 <em>intensely liked</em> films with the world.
//               </Text>
//               <Box
//                 align={["center", "center", "center"]}
//                 justify={["center", "center", "center"]}
//               >
//                 {!user ? (
//                   <Button
//                     bgColor="cyan.400"
//                     color={colorMode === "light" ? "grey.700" : "black"}
//                     my={4}
//                     py={8}
//                     px={2}
//                     onClick={() => navigate("/sign-up")}
//                   >
//                     Build your list!
//                   </Button>
//                 ) : (
//                   <Button
//                     bgColor="green.400"
//                     color={colorMode === "light" ? "white" : "white"}
//                     my={4}
//                     py={8}
//                     px={2}
//                     onClick={() => navigate("/mylist")}
//                   >
//                     Go To Your list!
//                   </Button>
//                 )}
//                 <Button py={8} px={7} ml={8} onClick={goToLearnMore}>
//                   Learn More
//                 </Button>
//               </Box>
//             </Box>
//           </Center>
//         </Box>

//         <Box display={["inline", "inline", "none", "none"]}>
//           <Image src={Mobile_Mockup}></Image>
//         </Box>

//         <Box display={["none", "none", "inline", "inline"]}>
//           <Image src={Laptop_Mockup}></Image>
//         </Box>
//       </SimpleGrid>

//       <Box my={10}>
//         <Divider />
//       </Box>

//       <Flex
//         justifyContent="center"
//         alignItems="center"
//         pb={12}
//         ref={learnMore}
//       >
//         <Heading size="lg">The Idea Is Simple</Heading>
//       </Flex>

//       <Wrap justify="center" spacing={4}>
//         <WrapItem>
//           <Box maxW={80} borderBottomWidth="1px" rounded="lg">
//             <Box>
//               <Text pl="6" textAlign="left" fontSize={24}>
//                 ⭐⭐⭐⭐🤔❓
//               </Text>
//             </Box>
//             <Box p="6">
//               <Box display="flex" alignItems="baseline">
//                 <Text fontSize={20} fontWeight="semibold">
//                   Traditional Film Rating systems aren't built to see how{" "}
//                   <u>intensely</u> a film is liked <i>over</i> other films.
//                   <br />
//                   We want to try and fix that.
//                 </Text>
//               </Box>
//             </Box>
//           </Box>
//         </WrapItem>

//         <WrapItem>
//           <Box maxW={80} borderBottomWidth="1px" rounded="lg">
//             <Box>
//               <Text pl="6" textAlign="left" fontSize={24}>
//                 ❤️🔥😄
//               </Text>
//             </Box>
//             <Box p="6">
//               <Box display="flex" alignItems="baseline">
//                 <Text fontSize={20} fontWeight="semibold">
//                   On <b>FilmX</b> you spend points to indicate how intensely
//                   you like a particular film.
//                   <br />
//                   <br />
//                   <i>But there's a catch</i>...
//                 </Text>
//               </Box>
//             </Box>
//           </Box>
//         </WrapItem>

//         <WrapItem>
//           <Box maxW={80} borderBottomWidth="1px" rounded="lg">
//             <Box>
//               <Text pl="6" textAlign="left" fontSize={24}>
//                 ✨🚀🤗
//               </Text>
//             </Box>
//             <Box p="6">
//               <Box display="flex" alignItems="baseline">
//                 <Text fontSize={20} fontWeight="semibold">
//                   Every <i>additional</i> point you spend will cost more!
//                   <br />
//                   <em>
//                     <b>
//                       How much are you really willing to spend to keep that
//                       film in your list?
//                     </b>
//                   </em>
//                 </Text>
//               </Box>
//             </Box>
//           </Box>
//         </WrapItem>
//       </Wrap>

//       <Box py={20}>
//         <Divider />
//       </Box>

//       <Center>
//         <Box h={96} w={["90vw", "90vw", "50vw", "50vw"]}>
//           <VStack>
//             <Box>
//               <VStack>
//                 <Heading>FilmX is in Early Beta 🧬</Heading>

//                 <Divider />

//                 <Box>
//                   <Text mb={8} fontWeight="semibold" fontSize={24}>
//                     Help us build a platform of honest and trustworthy film
//                     recommendations?
//                   </Text>
//                 </Box>
//               </VStack>
//             </Box>

//             <Button
//               bgColor="cyan.400"
//               color={colorMode === "light" ? "grey.700" : "black"}
//               p={8}
//               size="lg"
//               onClick={() => navigate("/sign-up")}
//             >
//               Build Your List!
//             </Button>
//           </VStack>
//         </Box>
//       </Center>

//       <Divider mb={9} />

//       <Flex pb={8} align="right" justify="right">
//         <Image src={TMDB_Logo} w={24} mr={3} />
//         <Text fontWeight="medium">
//           FilmX gets all it's film data from the TMDb API. FilmX is not
//           endorsed or certified by TMDb.
//         </Text>
//       </Flex>

//       <Divider mb={4} />

//       <Flex align="right" justify="right">
//         <Text fontWeight="medium">
//           Also, Lekh-Haq has nothing but the utmost love and regard for Clyde
//           Mendonca for helping us build this. <br />
//           Clyde, you're a gentleman and a savant. <i>Thank you</i>.
//         </Text>
//       </Flex>
//     </Container>

//     <Login isOpen={isOpenLogin} onClose={onCloseLogin} />
//   </>